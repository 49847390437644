import React from 'react';

const Page2 = () => {
    return (
        <div>
            Page2
        </div>
    );
};

export default Page2;