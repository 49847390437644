import React from 'react';

const Page1 = () => {
    return (
        <div>
            Page1
        </div>
    );
};

export default Page1;